window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});



(function() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://lgmnc.rdtk.io/track.js?rtkcmpid=66aab21162bdc000017fffd9';
    document.head.appendChild(script);
})();